import PropTypes from "prop-types"
import React from "react"
import logo1 from "../img/positive_life_logo.png"
import logo2 from "../img/positive-health-logo.png"
import logo3 from "../img/positive-health-logo.png"
import logo4 from "../img/health_and_life_logo.png"
import SiteInfo from "./siteinformation"
import Helmet from "react-helmet"

const facebookLink = new SiteInfo().getFacebookPage()

const Header = ({ siteTitle }) => (

<header className="header-section">
    <Helmet>
        {/* inline style elements */}
        { getSiteNumber() == 1 &&
        <style type="text/css">{`
        .recipe .recipe-info:after {

            height: 0;
            background: #000;
        }
        .recipe .recipe-info, .header-top {
            background: #242582;
            color: white;

        }
        a:visited, a:link, a:active {
            color: white
        }
    `}

        </style> }
        { getSiteNumber() == 4 &&
        <style type="text/css">{`
        .recipe .recipe-info:after {

            height: 0;
            background: #3D017A;
        }
        .recipe .recipe-info, .header-top {
            background: #3D017A;
            color: white;

        }
        a:visited, a:link, a:active {
            color: white
        }
    `}

        </style> }
    </Helmet>
  <div className="header-top">
    <div className="container">
      <div className="header-social">
      {/*  <a href={facebookLink}><i className="fa fa-facebook"></i></a>*/}
      </div>
     {/* <div className="user-panel">
        <a href="#">Register</a> /
        <a href="#">Login</a>
      </div>*/}
    </div>
  </div>
  <div className="header-bottom">
    <div className="container">
      <a href="/" className="site-logo">
        <img src={getHeaderLogo()} alt="" />
      </a>

    {/*  <div className="header-search">
        <a href="#"><i className="fa fa-search"></i></a>
      </div>*/}
      <ul className="main-menu">
        <li><a href="/">Home</a></li>
        <li><a href="/earnings/">Earnings</a></li>
        <li><a href="/privacy/">Privacy</a></li>
        <li><a href="/terms/">Terms and Conditions</a></li>
        <li><a href="/dmca/">DMCA</a></li>
        <li><a href={getEmail()}>Contact</a></li>
      </ul>
    </div>
  </div>
</header>)


function getHeaderLogo() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return logo2;
    }
    if (site == 3) {
        return logo3;
    }
    if (site == 4) {
        return logo4;
    }

    return logo1;
}

function getEmail() {
    var site = new SiteInfo();

    return "mailto: " + site.getAdminEmail();
}

function getSiteNumber() {
    var site = new SiteInfo().getSiteNumber();
    return site;
}



Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
