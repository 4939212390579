
import React from "react"
import PropTypes from "prop-types"
import SiteInfo from "./siteinformation"
import Layout from "./layout"

const MainLayout = ({children}) => {
/*    var site = new SiteInfo()
    if (site.getSiteNumber() == 10) {
        return (
            <LayoutSite2>
                {children}
            </LayoutSite2>
        )
    } else {*/
        return (
            <Layout>
                {children}
            </Layout>
        )
 /*   }
*/
}

export default MainLayout