/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, StaticQuery } from "gatsby"
import footerbg from "../img/footer-bg.jpg"
import BackgroundImage from 'gatsby-background-image'

import Header from "./header"
import StyledFooter from "./footer"
import "../css/animate.css"
import "../css/bootstrap.min.css"
import "../css/font-awesome.min.css"
import "../css/owl.carousel.css"

import SiteInfo from './siteinformation'

import  "../css/style.css"


var title = "Test";
title = getTitle();

const Layout = ({ children }) => {
  return (
    <>

    <Header siteTitle={title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 10,
        }}>

        <br/>
{/*
        <section class="hero-section">
          <div class="hero-slider owl-carousel">
            <div class="hero-slide-item set-bg" data-setbg="img/slider-bg-1.jpg">
              <div class="hs-text">
                <h2 class="hs-title-1"><span>Healthy Recipes</span></h2>
                <h2 class="hs-title-2"><span>from the best chefs</span></h2>
                <h2 class="hs-title-3"><span>for all the foodies</span></h2>
              </div>
            </div>
            <div class="hero-slide-item set-bg" data-setbg="img/slider-bg-2.jpg">
              <div class="hs-text">
                <h2 class="hs-title-1"><span>Healthy Recipes</span></h2>
                <h2 class="hs-title-2"><span>from the best chefs</span></h2>
                <h2 class="hs-title-3"><span>for all the foodies</span></h2>
              </div>
            </div>
          </div>
        </section>*/}

      {/*  <section class="add-section spad">
          <div class="container">
            <div class="add-warp">
              <div class="add-slider owl-carousel">
                <div class="as-item set-bg" data-setbg="img/add/1.jpg"></div>
                <div class="as-item set-bg" data-setbg="img/add/2.jpg"></div>
                <div class="as-item set-bg" data-setbg="img/add/3.jpg"></div>
              </div>
              <div class="row add-text-warp">
                <div class="col-lg-4 col-md-5 offset-lg-8 offset-md-7">
                  <div class="add-text text-white">
                    <div class="at-style"></div>
                    <h2>Amazing deserts</h2>
                    <ul>
                      <li>Easy to make</li>
                      <li>Step by Step Video Tutorial</li>
                      <li>Gluten Free</li>
                      <li>Healty  Ingredients</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
*/}




        <main>{children}</main>


        <StyledFooter></StyledFooter>

      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


function getTitle() {
    const site = process.env.GATSBY_SITE;
    if (site == 'positivelife.link') {
        return "Positive Life Link";
    }
    if (site == 'positivehealth.link') {
        return "Positive Health Link";
    }
}

export default Layout
