/**
 * Created by nick on 1/11/2020.
 */

class SiteInfo {
    getSiteNumber() {
      const site = process.env.GATSBY_SITE;
      if (site == 'positivelife.link') {
        return 1;
      }
      if (site == 'positivehealth.link') {
        return 2;
      }
      if (site == 'bodyhealthandfitness.com') {
        return 3;
      }
        if (site == 'healthandlife.link') {
            return 4;
        }
      return 1;
    }

    getFacebookPage() {
        if (this.getSiteNumber() == 1) {
            return "https://facebook.com/positivelifesite"
        }
      if (this.getSiteNumber() == 2) {
        return "https://facebook.com/positivehealthlink"
      }
      if (this.getSiteNumber() == 3) {
        return "https://facebook.com/bodyhealthandfitness"
      }
        if (this.getSiteNumber() == 4) {
            return "https://facebook.com/healthandlifesite"
        }
    }

    getAdminEmail() {
        if (this.getSiteNumber() == 1) {
            return "admin@positivelife.link"
        }
        if (this.getSiteNumber() == 2) {
            return "admin@positivehealth.link"
        }
        if (this.getSiteNumber() == 3) {
            return "admin@bodyhealthandfitness.com"
        }
        if (this.getSiteNumber() == 4) {
            return "admin@healthandlife.link"
        }
    }

    getPageTitle() {
        if (this.getSiteNumber() == 1) {
            return "Positive Life"
        }
        if (this.getSiteNumber() == 2) {
            return "Positive Health"
        }
        if (this.getSiteNumber() == 3) {
            return "Body Health and Fitness"
        }
        if (this.getSiteNumber() == 4) {
            return "Health And Life"
        }
    }

    getIsDevelopment() {
        return (process.env.DEVELOPMENT_FLAG === 'true')
    }
}

export default SiteInfo